<template>
  <v-container
    class="z-end-client"
  >
    <v-col cols="12">
      <v-img
        :src="imageAvatar"
        lazy-src="require('../../assets/img/Zwono_Logo-H-Colors-RGB-228x70.svg')"
        class="my-3"
        contain
        height="200"
        dark
      />
    </v-col>
    <v-col
      v-if="!endClientData || !endClientData.status ||!endClientData.delivery"
      class="mb-5"
      cols="12"
    >
      <h2>{{ $t('EndClient.status_page_not_found') }}</h2>
    </v-col>
    <v-col
      v-else-if="endClientData.status === 'Ordered' || endClientData.status === 'InProgress'"
      class="mb-5"
      cols="12"
    >
      <v-card 
        elevation="15" 
        outlined 
        tile
        class="z-delivery-time"
      >
        <v-card-title>{{ $t('EndClient.status_page_in_progress') }}</v-card-title>
        <v-card-text>
          <p>
            <b>{{ $t('EndClient.timeslot_date') }}&nbsp;</b> 
            <span class="d-inline-block">{{ formatDate(endClientData.delivery.delivery_date) }}</span>
          </p>
          <p>
            <b>{{ $t('EndClient.timeslot_time') }}</b>
            {{ endClientData.timeslot.start + " - " + endClientData.timeslot.end }}
          </p>
          <p><b>{{ $t('EndClient.timeslot_customer_note') }}</b> <span class="d-inline-block">{{ endClientData.note }}</span></p>
          <p><b>{{ $t('EndClient.status_page_driver') }}</b> <span class="d-inline-block">{{ endClientData.delivery.driver.user.fullName }}</span></p>
          <p><b>{{ $t('EndClient.status_page_drivers_phone') }}</b> <span class="d-inline-block">{{ endClientData.delivery.driver.user.phone }}</span></p>
          <p><b>{{ $t('EndClient.status_page_vehicle') }}</b> <span class="d-inline-block">{{ endClientData.delivery.vehicle.vehicle_registration }}</span></p>
          <p><b><h4>{{ $t('EndClient.status_page_nr_of_deliveries_before') }} <span class="d-inline-block">{{ endClientData.hops > -1 ? endClientData.hops : '/' }}</span></h4></b></p>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      v-else-if="endClientData.status === 'Delivered'"
      class="mb-5"
      cols="12"
    >
      <h2>{{ $t('EndClient.status_page_delivered') }}</h2>
    </v-col>
    <v-col
      v-else-if="endClientData.status === 'NotDelivered'"
      class="mb-5"
      cols="12"
    >
      <h2>{{ $t('EndClient.status_page_not_delivered') }}</h2>
    </v-col>
  </v-container>
</template>

<script>
import axiosInstance from '@/plugins/axiosInstance'
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'EndClientStatus',
  data: () => ({
    orderId: 0,
    imageAvatar: ''
  }),
  computed: {
    ...mapGetters('endclient', ['endClientData']),
  },
  async mounted() {
    const id = this.$route.query?.id
    if (id) {
      this.orderId = id;
      await this.initialize();
    }
  },
  methods: {
    ...mapActions('endclient', ['getEndClientData']),

    async initialize() {
      await Promise.all([
        this.getEndClientData(this.orderId),
        this.getAvatar()
      ]) 
      const lang = this.endClientData?.company?.endclient_lang;
      if (lang) {
        // sessionStorage.setItem('lang', lang);
        this.$i18n.locale = lang;
      }
    },
    formatDate(value) {
      return new Date(value).toLocaleDateString(this.$i18n.locale);
    },
    async getAvatar() {
      axiosInstance
        .get(`/orders/endclient/${this.orderId}/logo`, { responseType: 'arraybuffer' })
        .then((response) => {
          var content_type = response.headers['content-type'];
          const buffer = Buffer.from(response.data, 'binary').toString('base64');
          this.imageAvatar =
            `data:${content_type};charset=utf-8;base64,` + buffer;
        })
        .catch((error) => {
          if (process.env.NODE_ENV === 'development') {
            console.log('Get avatar error: ' + error);
          }
        });
    },
  }
}
</script>