// https://vuex.vuejs.org/en/mutations.html


export default {
    auth_request(state) {
        state.authStatus = 'loading'
    },
    auth_success(state, { token, user, company_id, role, search_address_api }) {
        state.authStatus = 'success'
        state.token = token
        state.user = user
        state.company_id = company_id
        state.role = role
        state.search_address_api = search_address_api
    },
    set_search_address_api(state, search_address_api) {
        state.search_address_api = search_address_api
        sessionStorage.setItem('search_address_api', search_address_api)
    },
    auth_error(state) {
        state.authStatus = 'error'
    },
    logout(state) {
        state.authStatus = ''
        state.token = ''
    },
    
}