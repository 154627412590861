// https://vuex.vuejs.org/en/state.html

export default {
    // authentication
    authStatus: '',
    token: sessionStorage.getItem('token') || '',
    role: sessionStorage.getItem('role') || '',
    company_id: sessionStorage.getItem('company_id') || '',
    user: {},
    search_address_api: sessionStorage.getItem('search_address_api') || '',
    
    deliveryStatusEnum: ['NotDelivered', 'Delivered'],
}