// Lib imports
import Vue from 'vue'
import axios from 'axios'
import NProgress from "nprogress";
import store from '@/store'
import router from '@/router'

// creates a new instance that you will call instead of axios.
export const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = process.env.VUE_APP_API_URL
axiosInstance.defaults.headers.get['Accept'] = 'application/json'


axiosInstance.interceptors.request.use(
    function(request) {
        const token = sessionStorage.getItem('token')
        if (token) {
            request.headers.common['Authorization'] = 'Bearer ' + token
        }
        
        NProgress.start();
        return request;
    },
    function(error) {
        if (process.env.NODE_ENV === 'development') {
            console.log('axios request interceptor error: ' + error);
        }
        NProgress.done();
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function(response) {
        NProgress.done();
        return response;
    },
    function(error) {
        if (error.response.status === 401) {
            store.dispatch('logout').then(() => { // Logout until no refreshToken
                if (router.currentRoute.path !== '/') router.push('/')
            })
            /* if (store.getters.authorized) {
                store.dispatch("refreshtoken");
            } */
        } 
        if (process.env.NODE_ENV === 'development') {
            console.log('axios response interceptor error: ' + error);
        }
        NProgress.done();
        return Promise.reject(error);
    }
);
// makes axiosInstance common base instance axios the default http handler
Vue.prototype.$http = axiosInstance

export default axiosInstance