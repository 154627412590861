// https://vuex.vuejs.org/en/actions.html
import axiosInstance from '@/plugins/axiosInstance'
import i18n from '../i18n/index';

/* The login action(function) first param is the vuex commit object, 
second is userData which is passed from where-ever you call that action.
You then create a promise in the "login"'s return which is where we put the code
that we will use to trigger mutations.

you want to set your userData properties to the same as mine(i.e username, password) or change them below to match what you have

This same format is carried with the rest of the functions.

Some do not need anything passed in besides the commit object like "logout"
*/
export default {
    login({ commit }, userData) {
        commit('auth_request')
        return axiosInstance.post('/auth/login', { email: userData.username, password: userData.password })
            .then(response => {
                if (response.status === 200) {
                    const token = response.data.token.access_token
                    const user = response.data.user
                    const search_address_api = user?.company?.search_address_api || ''
                    const fullname = response.data.user.fullName;
                    const company_id = (response.data.user.company) ? response.data.user.company.id : null
                    const company_name = (response.data.user.company) ? response.data.user.company.company_name : '/'
                    const role = response.data.user.role;
                    const lang = response.data.user.lang;
                    if (token) sessionStorage.setItem('token', token)
                    // if (user) sessionStorage.setItem('user', user)
                    sessionStorage.setItem('search_address_api', search_address_api)
                    if (fullname) sessionStorage.setItem('fullname', fullname)
                    if (company_id) sessionStorage.setItem('company_id', company_id)
                    if (company_name) sessionStorage.setItem('company_name', company_name)
                    if (role) sessionStorage.setItem('role', role)
                    // if (lang) sessionStorage.setItem('lang', lang)
                    if (lang) {
                        i18n.locale = lang;
                    }
                    commit('auth_success', { token, user, company_id, role, search_address_api });
                    return true
                } else {
                    commit('auth_error')
                    sessionStorage.removeItem('token')
                    if (process.env.NODE_ENV === 'development') {
                        console.log('login: ' + response)
                    }
                }
                return response
            })
    },
    logout({ commit }) {
        return new Promise((resolve, reject) => {
            commit('logout')
            sessionStorage.removeItem('token')
            // sessionStorage.removeItem('user')
            sessionStorage.removeItem('fullname')
            sessionStorage.removeItem('company_id')
            sessionStorage.removeItem('role')
            sessionStorage.removeItem('company_name')
            resolve().catch(error => reject(error))
        })
    },
    refreshtoken({ commit }) {
        return axiosInstance.get('/refresh')
            .then(response => {
                const token = response.data.accessToken
                if (token) sessionStorage.setItem('token', token)
                commit('auth_success', { token })
            })
            .catch(error => {
                commit('logout')
                sessionStorage.removeItem('token')
                if (process.env.NODE_ENV === 'development') {
                    console.log('refreshtoken: ' + error)
                }
            })
    },
}